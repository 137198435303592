import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '@radix-ui/react-icons';
import { useAtom, useSetAtom } from 'jotai';
import { ReactNode, useState } from 'react';

import { Button } from '@/components/ui/button';
import { requestedPayPeriodAtom, selectedDateForPayPeriodAtom } from '@/config/jotai.ts';
import { cn } from '@/lib/utils.ts';
import {
  getClosestPayPeriod,
  getNextRequestedPayPeriod,
  getPreviousRequestedPayPeriod,
  longDateString,
  shortDateString,
} from '@/utility/dateUtils';

import { Calendar } from './ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

const PayPeriodCalendar = ({ popoverTrigger }: { popoverTrigger: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const setRequestedPayPeriod = useSetAtom(requestedPayPeriodAtom);
  const [selectedDateForPayPeriod, setSelectedDateForPayPeriod] = useAtom(
    selectedDateForPayPeriodAtom
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{popoverTrigger}</PopoverTrigger>
      <PopoverContent className="flex w-auto flex-col justify-center p-0" align="start">
        <Button
          onClick={() => {
            const today = new Date();
            setRequestedPayPeriod(getClosestPayPeriod(today));
            setSelectedDateForPayPeriod(today);
            setOpen(false);
          }}
          className="m-4 mb-2"
        >
          Today
        </Button>
        <Calendar
          mode="single"
          selected={selectedDateForPayPeriod}
          onSelect={(date) => {
            if (date) {
              setRequestedPayPeriod(getClosestPayPeriod(date));
              setSelectedDateForPayPeriod(date);
              setOpen(false);
            }
          }}
          defaultMonth={selectedDateForPayPeriod}
        />
      </PopoverContent>
    </Popover>
  );
};

export const PayPeriodSelector = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  const [requestedPayPeriod, setRequestedPayPeriod] = useAtom(requestedPayPeriodAtom);

  return (
    <div className={cn('flex items-center justify-center gap-0.5 p-2', className)}>
      <Button
        size="sm"
        className="sm:rounded-r-none"
        onClick={() =>
          setRequestedPayPeriod(getPreviousRequestedPayPeriod(requestedPayPeriod.beginDate))
        }
      >
        <ArrowLeftIcon className="h-5 w-5 sm:h-7 sm:w-7" />
      </Button>
      <PayPeriodCalendar
        popoverTrigger={
          <div>
            <Button className="h-8 overflow-hidden px-3 font-normal sm:rounded-none xsonly:hidden">
              <CalendarIcon className="ml-auto h-5 w-5" />
            </Button>
            <Button
              className="h-8 overflow-hidden px-3 font-normal sm:hidden sm:rounded-none"
              variant="ghost"
            >
              <p className="w-48 text-center text-sm leading-7">
                {shortDateString(requestedPayPeriod.beginDate)} to{' '}
                {shortDateString(requestedPayPeriod.endDate)}
              </p>
            </Button>
          </div>
        }
      />

      <Button
        className="hidden rounded-l-none sm:inline-flex"
        size="sm"
        onClick={() =>
          setRequestedPayPeriod(getNextRequestedPayPeriod(requestedPayPeriod.beginDate))
        }
      >
        <ArrowRightIcon className="h-7 w-7" />
      </Button>
      <p className="pl-4 text-center text-lg xsonly:hidden">
        {longDateString(requestedPayPeriod.beginDate)} to{' '}
        {longDateString(requestedPayPeriod.endDate)}
      </p>
      <Button
        className="sm:hidden"
        size="sm"
        onClick={() =>
          setRequestedPayPeriod(getNextRequestedPayPeriod(requestedPayPeriod.beginDate))
        }
      >
        <ArrowRightIcon className="h-5 w-5" />
      </Button>
    </div>
  );
};
