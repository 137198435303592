import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { SetStateAction } from 'jotai';
import { Dispatch, useState } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { DateFilterType } from './TimeHistoryReportFormFields';

const PayPeriodDateMap = {
  'Jan A': new Date('January 1, 2025'),
  'Jan B': new Date('January 16, 2025'),
  'Feb A': new Date('February 1, 2025'),
  'Feb B': new Date('February 16, 2025'),
  'Mar A': new Date('March 1, 2025'),
  'Mar B': new Date('March 16, 2025'),
  'Apr A': new Date('April 1, 2025'),
  'Apr B': new Date('April 16, 2025'),
  'May A': new Date('May 1, 2025'),
  'May B': new Date('May 16, 2025'),
  'Jun A': new Date('June 1, 2025'),
  'Jun B': new Date('June 16, 2025'),
  'Jul A': new Date('July 1, 2025'),
  'Jul B': new Date('July 16, 2025'),
  'Aug A': new Date('August 1, 2025'),
  'Aug B': new Date('August 16, 2025'),
  'Sep A': new Date('September 1, 2025'),
  'Sep B': new Date('September 16, 2025'),
  'Oct A': new Date('October 1, 2025'),
  'Oct B': new Date('October 16, 2025'),
  'Nov A': new Date('November 1, 2025'),
  'Nov B': new Date('November 16, 2025'),
  'Dec A': new Date('December 1, 2025'),
  'Dec B': new Date('December 16, 2025'),
};

export const ReportDateFormField = <T extends FieldValues>({
  control,
  fieldName,
  label,
  placeholder,
  dateFilterType,
  useCurrentTime = false,
}: {
  control: Control<T>;
  fieldName: Path<T>;
  label: string;
  placeholder: string;
  dateFilterType: DateFilterType;
  useCurrentTime?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const now = new Date();
  const tenYearsAgo = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
  const lastTenYears: string[] = [];
  for (let i = 0; i < 11; i++) {
    lastTenYears.push((now.getFullYear() - i).toString());
  }

  const handleChange = (date: Date | undefined, onChange: (date: Date) => void) => {
    if (date) {
      if (useCurrentTime) {
        date.setHours(now.getHours());
        date.setMinutes(now.getMinutes());
        date.setSeconds(now.getSeconds());
        date.setMilliseconds(now.getMilliseconds());
      }
      onChange(date);
    }
  };

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="text-list-foreground">{label}</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  className={cn(
                    'overflow-hidden border-input pl-3 font-normal text-list-foreground'
                  )}
                >
                  <span>{field.value ? field.value.toDateString() : placeholder}</span>
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              {dateFilterType === 'date' ? (
                <Calendar
                  mode="single"
                  selected={field.value}
                  onSelect={(value) => {
                    handleChange(value, field.onChange);
                  }}
                  onDayClick={() => setOpen(false)}
                  defaultMonth={field.value}
                  required
                  fromYear={tenYearsAgo.getFullYear()}
                  fromMonth={tenYearsAgo}
                  toYear={now.getFullYear()}
                  toMonth={now}
                />
              ) : (
                <PayPeriodDatePicker
                  now={now}
                  tenYearsAgo={tenYearsAgo}
                  selected={field.value}
                  onSelect={field.onChange}
                  setOpen={setOpen}
                />
              )}
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const PayPeriodDatePicker = ({
  now,
  tenYearsAgo,
  setOpen,
  selected,
  onSelect,
}: {
  now: Date;
  tenYearsAgo: Date;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selected?: Date;
  onSelect: (date: Date) => void;
}) => {
  const [year, setYear] = useState<number>(selected?.getFullYear() ?? now.getFullYear());

  const currentPayPeriod = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() >= 16 ? 16 : 1
  );

  const handleDateSelection = (payPeriodDate: Date) => {
    selected = payPeriodDate;
    onSelect(selected);
    setOpen(false);
  };

  return (
    <div className="p-3">
      <div className="flex w-full flex-row justify-between p-2">
        <Button
          className="flex h-7 w-7 p-0 opacity-50"
          variant="outline"
          aria-label="Previous Year"
          disabled={year === tenYearsAgo.getFullYear()}
          onClick={() => year > tenYearsAgo.getFullYear() && setYear(year - 1)}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <div aria-label="Selected Year" className="flex items-center text-sm font-medium">
          {year}
        </div>
        <Button
          className="flex h-7 w-7 p-0 opacity-50"
          variant="outline"
          aria-label="Next Year"
          disabled={year === now.getFullYear()}
          onClick={() => year < now.getFullYear() && setYear(year + 1)}
        >
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
      </div>
      <div className="grid grid-cols-4">
        {Object.entries(PayPeriodDateMap).map(([payPeriod, date]) => {
          const payPeriodDate = new Date(year, date.getMonth(), date.getDate());

          const isCurrentPayPeriod =
            payPeriodDate.getFullYear() === currentPayPeriod.getFullYear() &&
            payPeriodDate.getMonth() === currentPayPeriod.getMonth() &&
            payPeriodDate.getDate() === currentPayPeriod.getDate();

          const isSelectedPayPeriod =
            payPeriodDate.getFullYear() === selected?.getFullYear() &&
            payPeriodDate.getMonth() === selected?.getMonth() &&
            payPeriodDate.getDate() === selected?.getDate();

          return (
            <Button
              key={payPeriod}
              className={`m-1 w-12 font-normal ${isCurrentPayPeriod && !isSelectedPayPeriod && currentPayPeriod.getFullYear() === year && 'bg-accent text-accent-foreground'}`}
              variant={isSelectedPayPeriod ? 'default' : 'ghost'}
              onClick={() => handleDateSelection(payPeriodDate)}
              disabled={
                (payPeriodDate.getFullYear() === currentPayPeriod.getFullYear() &&
                  payPeriodDate > currentPayPeriod) ||
                payPeriodDate < tenYearsAgo
              }
            >
              {payPeriod}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
