import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  type PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import type { ReactNode } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';

import { Button } from './button';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  paginate?: boolean;
  paginationState?: Partial<PaginationState>;
  dataTestId?: string;
  tableContainerClassName?: string;
  summary?: ReactNode;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  paginate,
  paginationState,
  dataTestId = 'data-table',
  tableContainerClassName,
  summary,
}: DataTableProps<TData, TValue>) {
  const defaultPageSize = import.meta.env.VITE_DEFAULT_PAGINATION_COUNT
    ? parseInt(import.meta.env.VITE_DEFAULT_PAGINATION_COUNT, 10)
    : 10;

  // Determine if pagination should be applied
  const shouldPaginate = paginate ?? data.length > defaultPageSize;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination:
        shouldPaginate && paginationState
          ? paginationState
          : shouldPaginate
            ? { pageSize: defaultPageSize, pageIndex: 0 }
            : { pageSize: data.length, pageIndex: 0 },
    },
  });

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex-grow overflow-auto rounded-md border bg-list">
        <Table data-testid={dataTestId} containerClassName={cn(' h-full', tableContainerClassName)}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody data-testid={`${dataTestId}-body`}>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  data-testid={`${dataTestId}-row`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow data-testid={`${dataTestId}-row`}>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {!shouldPaginate && summary}
      {shouldPaginate && (
        <div
          className={cn('flex items-center py-4', {
            'justify-between': !!summary,
            'justify-end': !summary,
          })}
        >
          {summary}
          <div className="flex items-center justify-end space-x-2">
            {table.getPageCount() > 1 && (
              <div className="flex w-[100px] items-center justify-center text-sm font-medium italic">
                Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </div>
            )}
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
