import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { ProjectCategory } from '@/types/ProjectCategory';
import { ProjectTask } from '@/types/ProjectTask';
import type { TimeHistoryReportFormValues } from '@/types/TimeHistoryReportFormValues';
import { TimeSheetChild } from '@/types/TimeSheetChild';
import { TimeSheetReportPayload } from '@/types/TimeSheetReportPayload';

type ReportData = TimeSheetReportPayload | undefined;

type ReportDataContext = {
  reportData: ReportData;
  setReportData: Dispatch<SetStateAction<ReportData>>;
  projects: TimeSheetChild[];
  projectTasks: ProjectTask[];
  projectCategories: ProjectCategory[];
  getTasksForSelectedProjects: (selectedProjectIds: string[]) => ProjectTask[];
  getCategoriesForSelectedProjects: (selectedProjectIds: string[]) => ProjectCategory[];
  getFilteredTimesheetEntries: (
    filterFormData: TimeHistoryReportFormValues
  ) => TimeSheetReportPayload['timeSheet'] | undefined;
};

const initialValues: ReportDataContext = {
  reportData: undefined,
  setReportData: () => undefined,
  projects: [],
  projectTasks: [],
  projectCategories: [],
  getTasksForSelectedProjects: () => [],
  getCategoriesForSelectedProjects: () => [],
  getFilteredTimesheetEntries: () => undefined,
};

export const ReportDataContext = createContext<ReportDataContext>(initialValues);

export const ReportDataProvider = ({ children }: { children: ReactNode }) => {
  const [reportData, setReportData] = useState<ReportData>(undefined);
  const [projects, setProjects] = useState<TimeSheetChild[]>([]);
  const [projectTasks, setProjectTasks] = useState<ProjectTask[]>([]);
  const [projectCategories, setProjectCategories] = useState<ProjectCategory[]>([]);

  useEffect(() => {
    const totalProjects: TimeSheetChild[] = [];
    const totalProjectTasks: ProjectTask[] = [];
    const totalProjectCategories: ProjectCategory[] = [];

    if (reportData) {
      reportData.timeSheet.forEach((entry) => {
        if (
          entry.project &&
          !totalProjects.some(
            (project) => JSON.stringify(project) === JSON.stringify(entry.project)
          )
        ) {
          totalProjects.push(entry.project);
        }
        if (
          entry.projectTask &&
          !totalProjectTasks.some(
            (projectTask) => JSON.stringify(projectTask) === JSON.stringify(entry.projectTask)
          )
        ) {
          totalProjectTasks.push(entry.projectTask);
        }
        if (
          entry.projectCategory &&
          !totalProjectCategories.some(
            (projectCategory) =>
              JSON.stringify(projectCategory) === JSON.stringify(entry.projectCategory)
          )
        ) {
          totalProjectCategories.push(entry.projectCategory);
        }
      });
      setProjects(totalProjects);
      setProjectTasks(totalProjectTasks);
      setProjectCategories(totalProjectCategories);
    }
  }, [reportData]);

  const getTasksForSelectedProjects = (selectedProjectsIds: string[]): ProjectTask[] => {
    const relatedProjectTasks: ProjectTask[] = [];

    if (reportData) {
      selectedProjectsIds.forEach((id) => {
        // Find selected project in submittable projects array
        const project = reportData.submittableProjects.find(
          (submittableProject) => id === submittableProject?.id
        );

        if (project) {
          // Extract out projectTasks associated with project
          project.tasks.forEach((task) => {
            // Check if associated task is in timesheet
            if (
              projectTasks.some(
                (projectTask) => JSON.stringify(projectTask) === JSON.stringify(task)
              ) &&
              !relatedProjectTasks.some(
                (projectTask) => JSON.stringify(projectTask) === JSON.stringify(task)
              )
            ) {
              relatedProjectTasks.push(task);
            }
          });
        }
      });
    }
    return relatedProjectTasks;
  };

  const getCategoriesForSelectedProjects = (selectedProjectsIds: string[]): ProjectCategory[] => {
    const relatedProjectCategories: ProjectTask[] = [];

    if (reportData) {
      selectedProjectsIds.forEach((id) => {
        // Find selected project in submittable projects array
        const project = reportData.submittableProjects.find(
          (submittableProject) => id === submittableProject?.id
        );

        if (project) {
          // Extract out projectTasks associated with project
          project.categories.forEach((category) => {
            // Check if associated category is in timesheet
            if (
              projectCategories.some(
                (projectCategory) => JSON.stringify(projectCategory) === JSON.stringify(category)
              ) &&
              !relatedProjectCategories.some(
                (projectCategory) => JSON.stringify(projectCategory) === JSON.stringify(category)
              )
            ) {
              relatedProjectCategories.push(category);
            }
          });
        }
      });
    }

    return relatedProjectCategories;
  };

  const getFilteredTimesheetEntries = ({
    projects,
    projectTasks,
    projectCategories,
  }: TimeHistoryReportFormValues) => {
    const filteredEntries = reportData?.timeSheet.filter((entry) => {
      if (!projects || projects.length === 0) {
        return true; // return everything since no projects are selected
      }

      const projectFilter = projects.some((project) => project.id === entry.projectId);

      if (!projectTasks || projectTasks.length === 0) {
        return projectFilter; // return only entries that match selected projects
      }

      const taskFilter = projectTasks.some((task) => task.id === entry.taskId);

      if (!projectCategories || projectCategories.length === 0) {
        return projectFilter && taskFilter; // return only entries that match selected projects and tasks
      }

      const categoryFilter = projectCategories.some((category) => category.id === entry.categoryId);

      // return only entries that match selected projects, tasks, and categories
      return projectFilter && taskFilter && categoryFilter;
    });

    return filteredEntries;
  };

  return (
    <ReportDataContext.Provider
      value={{
        reportData,
        setReportData,
        projects,
        projectTasks,
        projectCategories,
        getTasksForSelectedProjects,
        getCategoriesForSelectedProjects,
        getFilteredTimesheetEntries,
      }}
    >
      {children}
    </ReportDataContext.Provider>
  );
};
