import { queryGraphApi } from '@/utility/queryGraphApi';

// Function to get the user's profile image
export const getUserImage = async (): Promise<string | undefined> => {
  try {
    // Fetch the profile image as a blob.
    const blob = await queryGraphApi<Blob>({
      path: '/v1.0/me/photo/$value', // Microsoft Graph endpoint for user profile photo
      method: 'GET',
    });

    if (blob) {
      // Convert the blob to an object URL.
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    }
  } catch (error) {
    return undefined;
  }
};
