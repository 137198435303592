import { msalInstance } from '@/main';

import { getGraphAccessToken } from './getGraphAccessToken';

type Arguments = {
  path: string;
  body?: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
};

const apiBaseUrl = import.meta.env.VITE_GRAPH_API_BASE_URL;

export const queryGraphApi = async <T>({
  path,
  body,
  method,
}: Arguments): Promise<T | undefined> => {
  const graphAccessToken = await getGraphAccessToken(msalInstance);

  const isImage = path.endsWith('$value');

  const httpHeaders: HeadersInit = {
    Authorization: `Bearer ${graphAccessToken}`,
    'Content-Type': isImage ? 'image/jpeg' : 'application/json',
    ...(isImage ? {} : { Accept: 'application/json' }),
  };

  // Hit the API
  const response = await fetch(`${apiBaseUrl}${path}`, {
    body,
    headers: httpHeaders,
    method,
  });

  if (!response.ok) {
    const defaultErrorMessage = `Unable to query ${apiBaseUrl}${path}.`;
    const errorResponse = await response.json();
    throw new Error(
      `${errorResponse.Detail || defaultErrorMessage} Error code: ${response.status}`
    );
  }

  // If response status indicates "No Content", return undefined.
  if (response.status === 204) return undefined;

  // For an image, return blob; otherwise, assume JSON
  return isImage ? (response.blob() as Promise<T>) : response.json();
};
