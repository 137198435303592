import { TimeSheetReportPayload } from '@/types/TimeSheetReportPayload';
import { queryApi } from '@/utility/queryApi';

export type GetTimeSheetReportPayloadProps = {
  startDate: Date;
  endDate: Date;
};

export const getTimeSheetReportPayload = ({ startDate, endDate }: GetTimeSheetReportPayloadProps) =>
  queryApi<TimeSheetReportPayload>({
    method: 'GET',
    path: `/api/user/time-sheet-report-payload/${startDate.toISOString()}/${endDate.toISOString()}`,
  });
