import { useCallback, useState } from 'react';

import { useReportData } from '@/hooks/useReportData';
import { TimeHistoryReportFormValues } from '@/types/TimeHistoryReportFormValues';

import { EgSpinner } from '../EgSpinner';
import { TimeHistoryBarChart } from './TimeHistoryBarChart';
import { TimeHistoryReportForm } from './TimeHistoryReportForm';
import TimeHistoryReportTable from './TimeHistoryReportTable';

export const TimeHistoryReport = () => {
  const [waitingForFetch, setWaitingForFetch] = useState(false);
  const [formData, setFormData] = useState<TimeHistoryReportFormValues>({
    startDate: null,
    endDate: null,
    projects: [],
    projectTasks: [],
    projectCategories: [],
  });

  const { getFilteredTimesheetEntries } = useReportData();

  const onSubmit = useCallback(
    (formData: TimeHistoryReportFormValues) => {
      const timeSheet = getFilteredTimesheetEntries(formData);
      const selectedTasks = formData.projectTasks;
      const selectedCategories = formData.projectCategories;

      // Remove unrelated tasks and categories if related project has been unselected
      selectedTasks?.forEach((task) => {
        if (!timeSheet?.some((entry) => entry.projectTask?.id === task.id)) {
          const index = selectedTasks.indexOf(task);
          if (index > -1) {
            selectedTasks.splice(index, 1);
          }
        }
      });

      selectedCategories?.forEach((category) => {
        if (!timeSheet?.some((entry) => entry.projectCategory?.id === category.id)) {
          const index = selectedCategories.indexOf(category);
          if (index > -1) {
            selectedCategories.splice(index, 1);
          }
        }
      });
      formData.projectTasks = selectedTasks;
      formData.projectCategories = selectedCategories;

      setFormData(formData);
    },
    [getFilteredTimesheetEntries]
  );

  return (
    <div className="flex h-full w-full grow">
      <div className="grid w-full grid-flow-col gap-5 p-5">
        <aside className="flex w-min bg-list/95 p-5">
          <TimeHistoryReportForm onSubmit={onSubmit} setWaitingForFetch={setWaitingForFetch} />
        </aside>
        <main className="flex flex-col gap-5">
          {waitingForFetch && (
            <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-gray-700 bg-opacity-40">
              <EgSpinner />
            </div>
          )}
          <h2 className="pb-6 pt-6 text-center text-xl font-medium tracking-tight sm:text-3xl sm:font-normal">
            Time History Report
          </h2>
          <TimeHistoryBarChart filterData={formData} />
          <div className="h-full">
            <TimeHistoryReportTable filterData={formData} />
          </div>
        </main>
      </div>
    </div>
  );
};
