import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';

import { getTimeSheetReportPayload } from '@/api/getTimeSheetReportPayload';
import { useReportData } from '@/hooks/useReportData';
import { QueryKeys } from '@/lib/utils';
import {
  TimeHistoryReportFormSchema,
  TimeHistoryReportFormValues,
} from '@/types/TimeHistoryReportFormValues';
import { TimeSheetReportPayload } from '@/types/TimeSheetReportPayload';

import { Form } from '../ui/form';
import { useToast } from '../ui/use-toast';
import { TimeHistoryReportFormFields } from './TimeHistoryReportFormFields';

export const TimeHistoryReportForm = ({
  onSubmit,
  setWaitingForFetch,
}: {
  onSubmit: (formData: TimeHistoryReportFormValues) => void;
  setWaitingForFetch: Dispatch<SetStateAction<boolean>>;
}) => {
  const form = useForm<TimeHistoryReportFormValues>({
    defaultValues: {
      startDate: null,
      endDate: null,
      projects: [],
      projectTasks: [],
      projectCategories: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(TimeHistoryReportFormSchema),
  });

  const [startDate, endDate, projects, projectTasks, projectCategories] = useWatch({
    name: ['startDate', 'endDate', 'projects', 'projectTasks', 'projectCategories'],
    control: form.control,
  });

  const { setReportData } = useReportData();

  const selectedProjectIds: string[] = projects ? projects.map((project) => project.id) : [];

  const formState = useFormState({ control: form.control });

  const { toast } = useToast();

  const {
    data,
    isLoading,
    refetch,
    isFetched,
    error,
    isSuccess,
  }: UseQueryResult<TimeSheetReportPayload> = useQuery({
    queryFn: () => {
      if (startDate && endDate) {
        return getTimeSheetReportPayload({
          startDate: startDate,
          endDate: endDate,
        });
      }
    },
    queryKey: [QueryKeys.TimeSheetPayload, startDate, endDate],
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    toast({
      description: error.message,
      title: error.name,
      variant: 'destructive',
    });
  }, [error, toast]);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    form.trigger();
  }, [startDate, endDate, form]);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    if (!formState.isValid || startDate > endDate) {
      return;
    }
    refetch();
  }, [formState.isValid, refetch, startDate, endDate]);

  useEffect(() => {
    setReportData(data);
  }, [data, setReportData]);

  useEffect(() => {
    if (startDate && endDate) {
      form.handleSubmit(onSubmit)();
    }
  }, [projects, projectTasks, projectCategories, startDate, endDate, form, onSubmit]);

  useEffect(() => {
    setWaitingForFetch(isLoading);
  }, [isLoading, setWaitingForFetch]);

  return (
    <Form {...form}>
      <form id="time-history-report-filters" onSubmit={form.handleSubmit(onSubmit)}>
        <TimeHistoryReportFormFields
          isFetched={isFetched}
          isLoading={isLoading}
          isSuccess={isSuccess}
          selectedProjectIds={selectedProjectIds}
        />
      </form>
    </Form>
  );
};
