export const barColors: string[] = [
  '#4572A7', // blue
  '#AA4643', // red,
  '#89A54E', // green
  '#DB843D', // orange
  '#80699B', // purple
  '#92A8CD', // light blue
  '#3D96AE', // teal blue
  '#B5CA92', // light green
  '#A47D7C', // brown
];
