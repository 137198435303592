import { Dispatch, SetStateAction } from 'react';

import { Button } from '../ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { DateFilterType } from './TimeHistoryReportFormFields';

export const DateTypeSelector = ({
  dateFilterType,
  setDateFilterType,
}: {
  dateFilterType: DateFilterType;
  setDateFilterType: Dispatch<SetStateAction<DateFilterType>>;
}) => {
  const handleClick = () => {
    if (dateFilterType === 'date') {
      setDateFilterType('payPeriod');
    } else {
      setDateFilterType('date');
    }
  };

  return (
    <div className="flex flex-row" id="date-type-selector">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={dateFilterType === 'date' ? 'default' : 'outline'}
            type="button"
            className="mt-1 flex h-5 rounded-r-none p-1"
            onClick={handleClick}
          >
            <span className="px-1 font-normal">Date</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Filter by date</p>
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={dateFilterType === 'date' ? 'outline' : 'default'}
            type="button"
            className="mt-1 flex h-5 rounded-l-none p-1"
            onClick={handleClick}
          >
            <span className="px-1 font-normal">Pay Period</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Filter by pay period</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
