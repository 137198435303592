import { useRouter } from '@tanstack/react-router';
import { useState } from 'react';

import { ReportType } from '@/types/ReportType';

import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

export const ReportTypeDropdownMenu = ({
  currentReportType,
}: {
  currentReportType: ReportType;
}) => {
  const router = useRouter();
  const [selectedReport, setSelectedReport] = useState<ReportType>(currentReportType);

  const handleReportSelection = (reportType: ReportType) => {
    setSelectedReport(reportType);
    router.history.push(`/reports?reportType=${reportType.path}`);
  };

  return (
    <div className="px-6 py-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="default" size="sm">
            <DropdownMenuLabel>{selectedReport.label}</DropdownMenuLabel>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={() => {
              handleReportSelection({ label: 'Time History Report', path: 'time-history' });
            }}
          >
            Time History Report
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
