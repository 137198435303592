import { ProjectCategory } from '@/types/ProjectCategory';
import { ProjectTask } from '@/types/ProjectTask';
import { TimeHistoryReportChartDataSource } from '@/types/TimeHistoryReportChartDataSource';
import type {
  TimeHistoryReportTableDataSource,
  TimeHistoryReportTableRow,
} from '@/types/TimeHistoryReportTableDataSource';
import { TimeSheetChild } from '@/types/TimeSheetChild';
import type { TimeSheetReportPayload } from '@/types/TimeSheetReportPayload';
import { longDateString } from '@/utility/dateUtils';

type ToTimeHistoryReportTableDataSourceOpts = {
  timeSheet?: TimeSheetReportPayload['timeSheet'];
  userContext?: TimeSheetReportPayload['userContext'];
};

export function toTimeHistoryReportTableDataSource({
  timeSheet,
  userContext,
}: ToTimeHistoryReportTableDataSourceOpts): TimeHistoryReportTableDataSource {
  const columns: TimeHistoryReportTableDataSource['columns'] = [
    {
      accessorKey: 'userFullName',
      header: 'Resource',
    },
    {
      accessorKey: 'entryDate',
      header: 'Date',
    },
    {
      accessorKey: 'project',
      header: 'Project',
    },
    {
      accessorKey: 'task',
      header: 'Task',
    },
    {
      accessorKey: 'category',
      header: 'Category',
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      accessorKey: 'hours',
      header: 'Hours',
      cell: ({ getValue }) => {
        const hours = getValue() as number;
        return <div className="pr-1 text-right">{hours}</div>;
      },
    },
  ];

  const data = timeSheet?.map<TimeHistoryReportTableRow>((timeSheet) => ({
    id: timeSheet.id!,
    entryDate: longDateString(new Date(timeSheet.entryDate)),
    hours: timeSheet.hours,
    description: timeSheet.description,
    userFullName: userContext?.fullName || 'N/A',
    project: timeSheet.project!.displayName,
    task: timeSheet.projectTask!.displayName,
    category: timeSheet.projectCategory?.displayName || 'N/A',
  }));

  const totalHours = data?.reduce((acc, { hours }) => acc + hours, 0);

  return {
    columns,
    data: data || [],
    paginationState: {
      pageSize: 10,
    },
    totalHours: Number((totalHours || 0).toFixed(2)),
  };
}

const getProjectHours = (
  timeSheet: TimeSheetReportPayload['timeSheet'] | undefined,
  projectId: string
): number => {
  let hours = 0;
  if (timeSheet) {
    const projectEntries = timeSheet.filter((entry) => entry.projectId === projectId);
    projectEntries.forEach((entry) => {
      hours += entry.hours;
    });
  }

  return hours;
};

const getProjectTaskHours = (
  timeSheet: TimeSheetReportPayload['timeSheet'] | undefined,
  projectId: string,
  taskId: string
) => {
  let hours = 0;
  if (timeSheet) {
    const projectEntries = timeSheet.filter(
      (entry) => entry.projectId === projectId && entry.taskId === taskId
    );
    projectEntries.forEach((entry) => {
      hours += entry.hours;
    });
  }

  return hours;
};

type toTimeHistoryReportChartDataSourceOpts = {
  timeSheet?: TimeSheetReportPayload['timeSheet'];
  getTasksForSelectedProjects: (selectedProjectIds: string[]) => ProjectTask[];
};

export function toTimeHistoryReportChartDataSource({
  timeSheet,
  getTasksForSelectedProjects,
}: toTimeHistoryReportChartDataSourceOpts): TimeHistoryReportChartDataSource {
  const totalProjects: TimeSheetChild[] = [];
  const totalProjectTasks: ProjectTask[] = [];
  const totalProjectCategories: ProjectCategory[] = [];

  timeSheet?.forEach((entry) => {
    if (
      entry.project &&
      !totalProjects.some((project) => JSON.stringify(project) === JSON.stringify(entry.project))
    ) {
      totalProjects.push(entry.project);
    }
    if (
      entry.projectTask &&
      !totalProjectTasks.some(
        (projectTask) => JSON.stringify(projectTask) === JSON.stringify(entry.projectTask)
      )
    ) {
      totalProjectTasks.push(entry.projectTask);
    }
    if (
      entry.projectCategory &&
      !totalProjectCategories.some(
        (projectCategory) =>
          JSON.stringify(projectCategory) === JSON.stringify(entry.projectCategory)
      )
    ) {
      totalProjectCategories.push(entry.projectCategory);
    }
  });

  const chartDataEntries: TimeHistoryReportChartDataSource = [];
  totalProjects.forEach((project) => {
    const taskDataArray: Record<string, number>[] = [];
    const relatedProjectTasks = getTasksForSelectedProjects([project.id]);
    const projectData = {
      Total: parseFloat(getProjectHours(timeSheet, project.id).toFixed(1)),
      [`${project.displayName}-Total`]: parseFloat(
        getProjectHours(timeSheet, project.id).toFixed(1)
      ),
      Project: project.displayName,
      [project.displayName]: {},
    };

    totalProjectTasks.forEach((projectTask) => {
      if (
        relatedProjectTasks.some(
          (relatedProjectTask) => relatedProjectTask.displayName === projectTask.displayName
        )
      )
        taskDataArray.push({
          [projectTask.displayName]: parseFloat(
            getProjectTaskHours(timeSheet, project.id, projectTask.id).toFixed(1)
          ),
        });
    });

    const tasksObject = Object.assign({}, ...taskDataArray);
    projectData[project.displayName] = tasksObject;

    const chartDataEntry = { ...projectData };
    chartDataEntries.push(chartDataEntry);
  });

  return chartDataEntries;
}
