import * as z from 'zod';

const TimeSheetChild = z.object({
  id: z.string(),
  name: z.string(),
  displayName: z.string(),
});

export const TimeHistoryReportFormSchema = z
  .object({
    startDate: z.date().nullable(),
    endDate: z.date().nullable(),
    projects: z.array(TimeSheetChild),
    projectTasks: z.array(TimeSheetChild).optional(),
    projectCategories: z.array(TimeSheetChild).optional(),
  })
  .superRefine((val, ctx) => {
    if (!val.startDate) {
      ctx.addIssue({ code: 'custom', message: 'Start date is required', path: ['startDate'] });
    }

    if (!val.endDate) {
      ctx.addIssue({ code: 'custom', message: 'End date is required', path: ['endDate'] });
    }

    if (val.startDate && val.endDate && !(val.startDate === val.endDate)) {
      if (val.startDate > val.endDate) {
        ctx.addIssue({
          code: 'custom',
          message: 'Must come before end date',
          path: ['startDate'],
        });
      }

      if (val.endDate < val.startDate) {
        ctx.addIssue({
          code: 'custom',
          message: 'Must come after start date',
          path: ['endDate'],
        });
      }
    }

    if (!val.projects) {
      ctx.addIssue({ code: 'custom', message: 'Project is required', path: ['projects'] });
    }
  });

export type TimeHistoryReportFormValues = z.infer<typeof TimeHistoryReportFormSchema>;
