import { useMsal } from '@azure/msal-react';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

import { getUserImage } from '@/api/getUserImage';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useTheme } from '@/hooks/useTheme';

type MenuProps = ComponentPropsWithoutRef<'div'>;

export function Menu({ className, children, ...props }: MenuProps) {
  // Use MSAL to retrieve account info
  const { instance, accounts } = useMsal();
  // Get the user's name from the active account or first account in the list
  const userName = instance.getActiveAccount()?.name || accounts[0]?.name;
  const firstName = userName ? userName.split(' ')[0] : '';
  const { setTheme, theme } = useTheme();

  // Add state to hold the profile photo URL
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | undefined>(undefined);

  const logout = (): void => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  // On mount, call getUserProfile and update the state
  useEffect(() => {
    (async () => {
      const photoUrl = await getUserImage();
      if (photoUrl) {
        setProfilePhotoUrl(photoUrl);
      }
    })();
  }, []);

  // get the first and last initials of the user
  const getInitials = (name: string) => {
    const parts = name.split(' ').filter((part) => part); // Remove any empty parts
    if (parts.length === 1) {
      return parts[0][0].toUpperCase(); // If there's only one part, return the first letter
    }
    const firstNameInitial = parts[0][0].toUpperCase();
    const lastNameInitial = parts[parts.length - 1][0].toUpperCase();
    return firstNameInitial + lastNameInitial;
  };

  return (
    <div {...props} className={className}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="hover:bg-color-none rounded-full border border-input"
          >
            {profilePhotoUrl ? (
              <img
                className="rounded-full hover:brightness-110 focus:ring-2 focus:ring-white"
                src={profilePhotoUrl}
                alt="User Profile"
              />
            ) : (
              <div className="flex h-9 w-9 items-center justify-center rounded-full text-white">
                {userName ? getInitials(userName) : <DotsVerticalIcon />}
                <span className="sr-only">Menu</span>
              </div>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {userName && <div className="px-4 py-2 font-semibold">Hi, {firstName}!</div>}
          <DropdownMenuSeparator />
          {children}
          <DropdownMenuItem
            className={theme === 'light' ? 'bg-accent' : ''}
            onClick={() => setTheme('light')}
          >
            Light
          </DropdownMenuItem>
          <DropdownMenuItem
            className={theme === 'dark' ? 'bg-accent' : ''}
            onClick={() => setTheme('dark')}
          >
            Dark
          </DropdownMenuItem>
          <DropdownMenuItem
            className={theme === 'system' ? 'bg-accent' : ''}
            onClick={() => setTheme('system')}
          >
            System
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
