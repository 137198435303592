import type { HTMLAttributes } from 'react';

import { useReportData } from '@/hooks/useReportData';
import { toTimeHistoryReportTableDataSource } from '@/lib/mappers/timeHistoryReport';
import type { TimeHistoryReportFormValues } from '@/types/TimeHistoryReportFormValues';

import { DataTable } from '../ui/data-table';

type TimeHistoryReportTableProps = {
  filterData: TimeHistoryReportFormValues;
};

export default function TimeHistoryReportTable({
  filterData,
  ...rest
}: TimeHistoryReportTableProps & HTMLAttributes<HTMLDivElement>) {
  const { reportData, getFilteredTimesheetEntries } = useReportData();

  const timeSheet = getFilteredTimesheetEntries(filterData);
  const userContext = reportData?.userContext;

  const { columns, data, paginationState, totalHours } = toTimeHistoryReportTableDataSource({
    timeSheet,
    userContext,
  });

  return (
    <div className="h-full w-full" {...rest}>
      <DataTable
        tableContainerClassName="scrollbar"
        dataTestId="timeHistoryReportTable"
        columns={columns}
        data={data}
        paginate
        paginationState={paginationState}
        summary={<TimeHistoryReportTableSummary totalHours={totalHours} />}
      ></DataTable>
    </div>
  );
}

type TimeHistoryReportTableSummaryProps = {
  totalHours: number;
};

function TimeHistoryReportTableSummary({
  totalHours,
}: TimeHistoryReportTableSummaryProps & HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h3>
      Total Hours: <span className="font-semibold">{totalHours}</span>
    </h3>
  );
}
