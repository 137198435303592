import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import ScrollableContainer from '../ScrollableContainer';

interface Option<T> {
  label: string;
  value: T;
}

export const MultiSelect = <T,>({
  options,
  placeholder,
  onValueChange,
  disabled,
  clearSelected,
}: {
  options: Option<T>[];
  placeholder: string;
  onValueChange: (values: T[]) => void;
  disabled?: boolean;
  clearSelected?: boolean;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option<T>[]>([]);

  // Helper function to handle toggling values in the array
  function handleCheckedChange(option: Option<T>, checked: boolean) {
    setSelectedOptions((current) => {
      if (checked) {
        // Add item if checked
        const values = [...current, option];
        onValueChange(values.map((value) => value.value));
        return values;
      } else {
        // Remove item if unchecked
        const values = current.filter((val) => JSON.stringify(val) !== JSON.stringify(option));
        onValueChange(values.map((value) => value.value));
        return values;
      }
    });
  }

  // Filter selected options to only those that are still available in the options array
  const validSelectedOptions = selectedOptions.filter((selected) =>
    options.some((option) => option.value === selected.value)
  );

  // Display placeholder if nothing valid is selected
  const buttonLabel =
    validSelectedOptions.length > 0
      ? validSelectedOptions.length === 1
        ? validSelectedOptions[0].label
        : `${validSelectedOptions.length} Selected`
      : placeholder;

  useEffect(() => {
    if (clearSelected) {
      setSelectedOptions([]);
    }
  }, [clearSelected]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="w-[200px]">
        <Button
          className="flex h-9 items-center justify-start overflow-hidden border-input px-3 py-2 text-sm font-normal text-list-foreground"
          variant="outline"
          disabled={disabled}
        >
          <span className="truncate">{buttonLabel}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <ScrollableContainer>
          {options.map((option, index) => (
            <DropdownMenuCheckboxItem
              key={index}
              checked={selectedOptions.some(
                (value) => JSON.stringify(value) === JSON.stringify(option)
              )}
              onCheckedChange={(checked) => handleCheckedChange(option, checked)}
              // Prevent the dropdown from closing upon selection
              onSelect={(event) => event.preventDefault()}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
        </ScrollableContainer>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
