import { useFormContext } from 'react-hook-form';

import { ProjectTaskAndCategoryFormFields } from '@/components/ProjectTaskAndCategoryFormFields.tsx';
import { Project } from '@/types/Project';
import { TimeSheetEntryFormValues } from '@/types/TimeSheetEntryFormValues';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';

type TimeSheetEntryFormProps = {
  calendarFormField: React.ReactElement;
  defaultValues: Partial<TimeSheetEntryFormValues>;
  projects: Project[];
  submitPending: boolean;
};

export const TimeSheetEntryForm = ({
  calendarFormField,
  defaultValues,
  projects,
  submitPending,
}: TimeSheetEntryFormProps) => {
  const { control } = useFormContext<TimeSheetEntryFormValues>();

  return (
    <>
      <div className="grid grid-cols-2 items-center gap-3">
        {calendarFormField}
        <FormField
          control={control}
          name="hours"
          disabled={submitPending}
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel htmlFor="number">Hours</FormLabel>
              <FormControl>
                <Input disabled={submitPending} {...field} />
              </FormControl>
              <FormMessage fixed={false} />
            </FormItem>
          )}
        />
      </div>

      <ProjectTaskAndCategoryFormFields
        defaultStintId={defaultValues.project}
        projects={projects}
        submitPending={submitPending}
      />

      <FormField
        control={control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Textarea
                placeholder="Write out the description of the time sheet entry"
                className="resize-y"
                disabled={submitPending}
                {...field}
              />
            </FormControl>
            {(!field.value?.length || field.value.length <= 300) && (
              <FormDescription>
                {300 - (field.value?.length ?? 0)} characters remaining
              </FormDescription>
            )}
            <FormMessage fixed={false} />
          </FormItem>
        )}
      />
    </>
  );
};
