import { createFileRoute } from '@tanstack/react-router';

import { Reports } from '@/components/reporting/Reports';
import { UnderConstruction } from '@/components/UnderConstruction';
import { featureFlags } from '@/lib/featureFlags';
import { ReportType } from '@/types/ReportType';

type ReportingSearchParams = {
  reportType: ReportType['path'];
};

export const Route = createFileRoute('/reports')({
  validateSearch: (search: Record<string, unknown>): ReportingSearchParams => {
    return { reportType: search.reportType as ReportType['path'] };
  },
  component: featureFlags.reporting ? Reports : UnderConstruction,
});
