import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { PayPeriodHoliday } from '@/types/PayPeriodHoliday';

type HolidayDatesContext = {
  holidays: PayPeriodHoliday[];
  setHolidays: Dispatch<SetStateAction<PayPeriodHoliday[]>>;
};

const initialValues: HolidayDatesContext = {
  holidays: [],
  setHolidays: () => [],
};

export const HolidayDatesContext = createContext<HolidayDatesContext>(initialValues);

export const HolidayDatesProvider = ({ children }: { children: ReactNode }) => {
  const [holidays, setHolidays] = useState<PayPeriodHoliday[]>([]);

  return (
    <HolidayDatesContext.Provider
      value={{
        holidays,
        setHolidays,
      }}
    >
      {children}
    </HolidayDatesContext.Provider>
  );
};
