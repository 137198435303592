import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useReportData } from '@/hooks/useReportData';
import { TimeHistoryReportFormValues } from '@/types/TimeHistoryReportFormValues';

import { Button } from '../ui/button';
import { FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { MultiSelect } from '../ui/multi-select';
import { Separator } from '../ui/separator';
import { DateTypeSelector } from './DateTypeSelector';
import { ReportDateFormField } from './ReportDateFormField';

export type DateFilterType = 'payPeriod' | 'date';

export const TimeHistoryReportFormFields = ({
  isFetched,
  isLoading,
  isSuccess,
  selectedProjectIds,
}: {
  isFetched: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  selectedProjectIds: string[];
}) => {
  const [dateFilterType, setDateFilterType] = useState<DateFilterType>('payPeriod');

  const { control, reset } = useFormContext<TimeHistoryReportFormValues>();

  const [startDate, endDate] = useWatch({
    name: ['startDate', 'endDate'],
    control: control,
  });

  const {
    projects: allProjects,
    getTasksForSelectedProjects,
    getCategoriesForSelectedProjects,
  } = useReportData();

  const isFilterDisabled =
    isLoading || !startDate || !endDate || allProjects.length === 0 || !isSuccess;

  const relatedProjectTasks = getTasksForSelectedProjects(selectedProjectIds);
  const relatedProjectCategories = getCategoriesForSelectedProjects(selectedProjectIds);

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-full flex-col flex-wrap">
        <h3 className="mb-2 font-medium text-list-foreground">Date Range</h3>
        <div className="justify-left flex flex-row gap-3 pb-4">
          <label className="font-sm mb-2 text-list-foreground" htmlFor="date-type-selector">
            By:
          </label>
          <DateTypeSelector dateFilterType={dateFilterType} setDateFilterType={setDateFilterType} />
        </div>
        <ReportDateFormField
          control={control}
          fieldName="startDate"
          label="Start Date"
          placeholder="Select start date..."
          dateFilterType={dateFilterType}
        />
        <ReportDateFormField
          control={control}
          fieldName="endDate"
          label="End Date"
          placeholder="Select end date..."
          dateFilterType={dateFilterType}
          useCurrentTime
        />
        <Separator className="mb-6 mt-0" />

        <h3 className="mb-2 font-medium text-list-foreground">Filters</h3>
        <FormField
          control={control}
          name="projects"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel className="text-list-foreground">Project</FormLabel>
              <MultiSelect
                options={allProjects.map((project) => ({
                  label: project.displayName,
                  value: project,
                }))}
                placeholder={
                  (allProjects.length === 0 && isFetched) || !isSuccess
                    ? 'No Projects'
                    : 'Select a project...'
                }
                onValueChange={field.onChange}
                disabled={isFilterDisabled}
                clearSelected={selectedProjectIds.length === 0}
              />
              <FormMessage className="w-44" />
            </FormItem>
          )}
        />
        {relatedProjectTasks.length > 0 && (
          <FormField
            control={control}
            name="projectTasks"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-list-foreground">Task</FormLabel>
                <MultiSelect
                  options={relatedProjectTasks.map((projectTask) => ({
                    label: projectTask.displayName,
                    value: projectTask,
                  }))}
                  placeholder="Select a task..."
                  onValueChange={field.onChange}
                  disabled={isFilterDisabled || selectedProjectIds.length === 0}
                  clearSelected={isFilterDisabled || selectedProjectIds.length === 0}
                />
                <FormMessage className="w-44" />
              </FormItem>
            )}
          />
        )}
        {relatedProjectCategories.length > 0 && (
          <FormField
            control={control}
            name="projectCategories"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-list-foreground">Category</FormLabel>
                <MultiSelect
                  options={relatedProjectCategories.map((projectCategory) => ({
                    label: projectCategory.displayName,
                    value: projectCategory,
                  }))}
                  placeholder="Select a category..."
                  onValueChange={field.onChange}
                  disabled={isFilterDisabled || selectedProjectIds.length === 0}
                  clearSelected={isFilterDisabled || selectedProjectIds.length === 0}
                />
                <FormMessage className="w-44" />
              </FormItem>
            )}
          />
        )}
      </div>
      <div className="flex justify-center gap-3">
        <Button
          variant="outline"
          className="flex border-input p-3 text-list-foreground"
          onClick={() => {
            reset({
              startDate: startDate,
              endDate: endDate,
              projects: [],
              projectTasks: [],
              projectCategories: [],
            });
          }}
          type="submit"
        >
          Reset Filters
        </Button>
      </div>
    </div>
  );
};
