import { getRouteApi } from '@tanstack/react-router';

import { Menu } from '@/components/Menu.tsx';
import { ReportDataProvider } from '@/contexts/ReportDataProvider';
import { ReportType } from '@/types/ReportType';

import { BetaBar } from '../BetaBar';
import { TimeTrackerButton } from '../TimeTrackerButton';
import { ReportTypeDropdownMenu } from './ReportTypeDropdownMenu';
import { TimeHistoryReport } from './TimeHistoryReport';

const route = getRouteApi('/reports');

export const Reports = () => {
  const { reportType } = route.useSearch();

  let fullReportType: ReportType = { label: 'Select Report Type...', path: null };

  switch (reportType) {
    case 'time-history':
      fullReportType = { label: 'Time History Report', path: 'time-history' };
      break;
  }

  return (
    <>
      <BetaBar />
      <header className="top-0 z-50 w-full bg-navigation text-navigation-foreground backdrop-blur supports-[backdrop-filter]:bg-navigation-transparent/75">
        <div className="bg-navigation/95 p-2.5">
          <h1 className="mb-1 scroll-m-20 text-center text-xl font-medium tracking-tight sm:text-4xl sm:font-normal">
            Reports
          </h1>
          <div className="absolute right-4 top-2 flex sm:top-3">
            <div className="flex items-center sm:pr-3">
              <TimeTrackerButton />
              <Menu className="sm:inline-flex" />
            </div>
          </div>
        </div>
        <ReportTypeDropdownMenu currentReportType={fullReportType} />
      </header>
      <ReportDataProvider>
        {reportType === 'time-history' && <TimeHistoryReport />}
      </ReportDataProvider>
    </>
  );
};
