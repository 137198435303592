import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { barColors } from '@/components/ui/colors';
import { useReportData } from '@/hooks/useReportData';
import { toTimeHistoryReportChartDataSource } from '@/lib/mappers/timeHistoryReport';
import { TimeHistoryReportChartDataSource } from '@/types/TimeHistoryReportChartDataSource';
import { TimeHistoryReportFormValues } from '@/types/TimeHistoryReportFormValues';

const chartConfig = {
  label: {
    color: 'hsl(var(--list-foreground))',
  },
} satisfies ChartConfig;

const getTooltipLabel = (label: string) => {
  if (label.includes('-Total')) {
    const labelArray = label.split('-Total');
    return labelArray[0];
  } else {
    const labelArray = label.split('.');
    return labelArray[1];
  }
};

const largestToSmallest = ([, valueA]: [string, number], [, valueB]: [string, number]) =>
  valueB - valueA;

const sortProjectTasks = (tasks: object) => {
  const sortedTasks = Object.entries(tasks).sort(largestToSmallest);
  return sortedTasks;
};

export const TimeHistoryBarChart = ({
  filterData,
}: {
  filterData: TimeHistoryReportFormValues;
}) => {
  const [chartData, setChartData] = useState<TimeHistoryReportChartDataSource>();
  const { getFilteredTimesheetEntries, getTasksForSelectedProjects } = useReportData();

  const [colorMap, setColorMap] = useState<Record<string, number>>({});

  useEffect(() => {
    const timeSheet = getFilteredTimesheetEntries(filterData);
    setChartData(
      toTimeHistoryReportChartDataSource({
        timeSheet,
        getTasksForSelectedProjects,
      })
    );
  }, [filterData, getFilteredTimesheetEntries, getTasksForSelectedProjects]);

  useEffect(() => {
    if (!chartData) {
      return;
    }

    let currentColor = -1;
    const tempColorMapArray = chartData.map((bar) => {
      const unsortedProjectTasks = bar[bar.Project];
      const sortedProjectTasks = sortProjectTasks(
        typeof unsortedProjectTasks === 'object' ? unsortedProjectTasks : {}
      );
      if (sortedProjectTasks.length === 0 && Object.entries(colorMap).length !== 0) {
        currentColor += 1;
        return [{ [bar.Project]: currentColor }];
      } else {
        return sortedProjectTasks.map(([key]: [string, number]) => {
          currentColor += 1;
          return { [`${bar.Project}.${key}`]: currentColor };
        });
      }
    });

    const tempColorMapObject = {};
    tempColorMapArray.forEach((map) => {
      Object.assign(tempColorMapObject, ...map);
    });

    if (Object.entries(colorMap).length === 0) {
      setColorMap(tempColorMapObject);
    }

    if (Object.entries(tempColorMapObject).length >= Object.entries(colorMap).length) {
      setColorMap(tempColorMapObject);
    }
    // TODO: Is there a way to accomplish this while still abiding by the exaustive deps rule?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.startDate, filterData.endDate]);

  const largest =
    (chartData?.reduce((max, entry) => Math.max(max, Number(entry.Total)), 0) ?? 0) * 1.05;

  return (
    <Card className="bg-list">
      <CardHeader>
        <CardTitle>Timesheet Hours</CardTitle>
        <CardDescription>
          {filterData.startDate && filterData.endDate
            ? `${filterData.startDate.toLocaleDateString()} - ${filterData.endDate.toLocaleDateString()}`
            : 'Select a date...'}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
          <BarChart
            accessibilityLayer
            data={chartData}
            layout="vertical"
            margin={{
              right: 30,
              top: 10,
            }}
          >
            <CartesianGrid
              horizontal={false}
              vertical={true}
              stroke="hsl(var(--muted-foreground))"
            />
            <YAxis
              type="category"
              dataKey="Project"
              width={110}
              yAxisId={0}
              className="fill-[--color-label]"
            />
            <YAxis
              type="category"
              dataKey="Project"
              width={110}
              yAxisId={1}
              className="fill-[--color-label]"
              hide={true}
            />
            <XAxis type="number" dataKey="Total" scale="linear" domain={[0, largest]} />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" labelValueGetter={getTooltipLabel} />}
            />
            {chartData?.map((bar, outerIndex) => {
              const unsortedProjectTasks = bar[bar.Project];
              const sortedProjectTasks = sortProjectTasks(
                typeof unsortedProjectTasks === 'object' ? unsortedProjectTasks : {}
              );
              if (sortedProjectTasks.length === 0) {
                return (
                  <Bar
                    key={`${bar.Project}-${outerIndex}`}
                    dataKey={`${bar.Project}-Total`}
                    layout="vertical"
                    fill={barColors[colorMap[`${bar.Project}`]]}
                    radius={4}
                    yAxisId={0}
                    stackId={0}
                  >
                    <LabelList
                      dataKey={`${bar.Project}-Total`}
                      position="center"
                      className="fill-[--color-label]"
                      fontSize={12}
                    />
                  </Bar>
                );
              } else {
                return sortedProjectTasks.map(([key, value]: [string, number], index) => {
                  if (key !== 'Total' && key !== 'Project' && key !== `${bar.Project}-Total`) {
                    return (
                      <Bar
                        key={`${index} + ${outerIndex}`}
                        dataKey={`${bar.Project}.${key}`}
                        layout="vertical"
                        fill={barColors[colorMap[`${bar.Project}.${key}`]]}
                        radius={4}
                        stackId={0}
                      >
                        {value !== 0 && (
                          <LabelList
                            dataKey={`${bar.Project}.${key}`}
                            position="center"
                            className="fill-[--color-label]"
                            fontSize={12}
                          />
                        )}
                      </Bar>
                    );
                  }
                });
              }
            })}
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};
